var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-wrap" },
    _vm._l(_vm.imgList, function (item, index) {
      return _c("el-image", {
        key: index,
        staticStyle: {
          width: "100px",
          height: "100px",
          "margin-right": "20px",
        },
        attrs: { src: item, "preview-src-list": _vm.imgList },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }