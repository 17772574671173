<template>
  <div class="img-wrap">
    <el-image
      v-for="(item, index) in imgList"
      :key="index"
      style="width: 100px; height: 100px;margin-right:20px"
      :src="item"
      :preview-src-list="imgList"
    >
    </el-image>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  props: {
    formConfig: {
      type: Object,
    },
  },
  data() {
    return {
      imgList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
  // 初始化数据
    async init() {
      console.log(this.formConfig);
      const { row } = this.formConfig;
      const data = await request.post('/tpm/tpmactcancel/findAttachmentList', { id: row.id }).then((res) => (res.success ? res.result : {}));
      this.imgList = data.map((v) => v.url);
      console.log(data);
    },
  },
};
</script>

<style scoped>
.img-wrap{
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 20px;
}
</style>
