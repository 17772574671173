import BasePage from '@/found/components/table_page';
import ViewAttachments from './photo.vue';

export default {
  name: 'cancel_obtaining_evidence_list',
  extends: BasePage,
  data() {
    return {
      params: {

      },
    };
  },
  components: {
    ViewAttachments,
  },
  async created() {
    await this.getConfigList('cancel_obtaining_evidence_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view_attachments') {
        this.formName = 'ViewAttachments';
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = '查看附件';
        this.openFull();
      }
    },
  },
};
